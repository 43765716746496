export function getMainBackendData() {
    return `query {
        getPaymentMethods {
            success
            message
            description
            data{
                name
                code
            }
        }
        getSalesConditions{
            success
            message
            description
            data{
                name
                code
            }
        }
        getEproofTypes{
            success
            message
            description
            data{
                name
                code
            }
        }
        getTaxRates{
          description
          code
          success
          data{
            code
          name
            rate
          }
        }
        getTaxCodes{
          description
          code
          success
          data{
            code
            name
          }
        }
        getExonerationDocTypes{
          description
          success
          code
          data{
          code
            name
          }
        }
        getReferenceDocTypes{
          description
          success
          code
          data{
          code
            name
          }
        }
        getDollarCurrencyExchange{
            success
            message
            description
            data{
              venta{
                fecha
                valor
              }
              compra{
                fecha
                valor
              }
            }
        }

        getServiceUnits{
            description
            success
            data{
              symbol
              description
          }
        }

        getProductUnits{
              description
              success
              data{
                symbol
                description
            }
        }


    }`
}