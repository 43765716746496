import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessiondataService } from '../sessiondata/sessiondata.service';




@Injectable({
  providedIn: 'root'
})
export class GraphqlConnectionService {

  uri = environment.uri;

  constructor(
    private sessiondata: SessiondataService
    ) { 

  }

  async post(query, needsToken:boolean =  false){
    try {
      let headers: any = {'content-type': 'application/json'}
      if(needsToken){
        const token = await this.sessiondata.getToken();
        console.log("token "+token)
        console.log(token);
        if(token){
          headers = {...headers, 'authorization': token }
        }
      }
      console.log(query);
      const response = await fetch(this.uri, {
        headers:headers ,
        method: 'POST',
        body: JSON.stringify({ query }),
      });
      
      const responseJson = await response.json();
      console.log(JSON.stringify(responseJson));
      return responseJson;
    }catch(e){
      console.log(e);
      return null;
    }
  }


  async get(query){
    try {
      const response = await fetch(this.uri, {
        headers: {'content-type': 'application/json', "Access-Control-Allow-Origin": "*"},
        method: 'GET',
        body: JSON.stringify({ query }),
      });

      const responseJson = await response.json();
      return responseJson;
    }catch(e){
      return null;
    }
  }
}
